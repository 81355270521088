@import '../../styles/mixins.scss';

.whoSection {
  background-color: $dark;
  color: $white;
  margin-top: -1px;
  text-align: left;
  padding: 109px 100px 174px 100px;

  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);

  @include xs-to-sm {
    padding-left: 20px;
    padding-right: 20px;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 95%);
  }

  strong {
    @include roboto-bold;
  }

  .title {
    @include roboto-bold;
    margin-bottom: 60px;
    text-align: center;
    font-size: 42px;
    line-height: 35px;

    @include xs-to-sm {
      font-size: 36px;
      text-align: left;
      margin-bottom: 18px;
      margin-top: -50px;
    }
  }

  .content {
    font-size: 18px;

    p {
      margin-bottom: 1.375rem;
    }

    .text-italic {
      @include roboto-medium-italic;
    }
  }
}
