@import '../../styles/mixins.scss';

.howSection {
  display: flex;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 48px;
  margin-top: 109px;

  @include xs-to-sm {
    flex-direction: column;
    padding: 0 20px;
    margin-top: 0;
  }

  .left {
    width: 50%;

    & h1 {
      @include roboto-bold;
      font-size: 42px;
      margin-bottom: 30px;

      @include xs-to-sm {
        font-size: 36px;
        line-height: 36px;
      }
    }

    p {
      font-size: 18px;
      margin-bottom: 1.375rem;
    }

    @include xs-to-sm {
      width: 100%;
    }
  }

  .right {
    margin-left: 56px;
    padding-right: 35px;

    @include xs-to-sm {
      margin: 0;
      margin-top: 20px;
      padding-right: 0;
    }

    & img {
      @include xs-to-sm {
        width: 100%;
      }
    }
  }
}
