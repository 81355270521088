/**
 * Mixins for different layout sizes: xs, sm, md, lg.
 * Breaking points are defined in _variables.scss
 * The range mixins A-to-B all means "for the sizes from A to B, both
 * inclusive", in particular it means that mixin A-to-lg is equivalent to
 * all sizes from A (inclusive) and larger.
 *
 * NOTE: For convenience, these mixins are sorted not alphabetically, but,
 * first, by increase of the first size; second, by increase of the second size.
 */

/* Break points. */
$screen-xs: 320px !default;
$screen-sm: 768px !default;
$screen-md: 1024px !default;
$screen-lg: 1200px !default;

/* XS */
@mixin xs {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

@mixin xs-to-sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin xs-to-md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin xs-to-lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

/* XS */
@mixin sm {
  @media (min-width: #{$screen-xs + 1px}) and (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin sm-to-md {
  @media (min-width: #{$screen-xs + 1px}) and (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin sm-to-xl {
  @media (min-width: #{$screen-xs + 1px}) {
    @content;
  }
}

/* MD */
@mixin md {
  @media (min-width: #{$screen-sm + 1px}) and (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin md-to-lg {
  @media (min-width: #{$screen-sm + 1px}) and (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin md-to-xl {
  @media (min-width: #{$screen-sm + 1px}) {
    @content;
  }
}

/* LG */
@mixin lg {
  @media (min-width: #{$screen-md + 1px}) and (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin lg-to-xl {
  @media (min-width: #{$screen-md + 1px}) {
    @content;
  }
}

/* XL */
@mixin xl {
  @media (min-width: #{$screen-lg + 1px}) {
    @content;
  }
}
