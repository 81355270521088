@import '../../styles/mixins.scss';

.PrivacyPolicy {
    .PrivacyPolicy__content {
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .PrivacyPolicy__title, h1 {
        font-size: 24px;
        font-weight: 700;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .PrivacyPolicy__title, h2 {
        font-size: 18px;
        font-weight: 700;
        margin: 5px 0px;
    }

    .PrivacyPolicy__text, p {
        font-size: 16px;
        line-height: 1.5;
        padding: 0 15px;
    }
}