@import '../../styles/mixins.scss';

.footer {
  border-top: 1px solid #aaa;
  margin: 0 100px;
  margin-top: 78px;
  font-size: 15px;
  text-align: center;
  color: #555;

  & p {
    margin-top: 17px;
    padding-bottom: 15px;
  }

  & a {
    color: #555;
    text-decoration: underline;
  }

  .tnc {
    @include xs-to-sm {
      display: block;
    }
  }

  @include xs-to-sm {
    margin: 0 20px;
  }
}
