@import '../../styles/mixins.scss';

.whySection {
  background-color: $dark;
  color: $white;
  padding-top: 217px;
  clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%);

  @include xs-to-sm {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
    padding-top: 137px;
  }

  strong {
    @include roboto-bold;
  }

  .title {
    @include roboto-bold;
    text-align: center;
    font-size: 42px;

    @include xs-to-sm {
      font-size: 36px;
      text-align: left;
      padding: 0 20px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding: 0 100px;
    font-size: 18px;

    @include xs-to-sm {
      flex-direction: column;
      padding: 0 20px;
      margin-top: 20px;
    }

    .left {
      width: 100%;
    }

    .right {
      margin-left: 73px;
      width: 100%;

      @include xs-to-sm {
        margin: 0;
      }

      & p {
        margin-top: 30px;
      }

      & iframe {
        @include xs-to-sm {
          width: 100%;
        }
      }
    }
  }
}
