@import '../../styles/mixins.scss';

.links {
  padding: 0 32px 0 90px;

  @include xs-to-sm {
    padding: 0 20px;
  }

  .title {
    @include roboto-bold;
    text-align: center;
    font-size: 42px;

    @include xs-to-sm {
      text-align: left;
      font-size: 36px;
    }
  }

  .linksContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px;

    @include xs-to-sm {
    }
  }
}
