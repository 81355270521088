$base-url: '../../assets/fonts/';

@font-face {
  font-family: 'Roboto-Regular';
  src: url($base-url + 'Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url($base-url + 'Roboto-Medium.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url($base-url + 'Roboto-Bold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Roboto-Medium-Italic';
  src: url($base-url + 'Roboto-MediumItalic.ttf') format('truetype');
}
