@import '../../styles/mixins.scss';

.dataPrivacySection {
  margin-top: 50px;
  background-color: $grey;
  padding-top: 200px;
  padding-bottom: 200px;
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);

  @include xs-to-sm {
    padding-top: 80px;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 97%);
  }

  .title {
    @include roboto-bold;
    font-size: 42px;
    text-align: center;

    @include xs-to-sm {
      text-align: left;
      margin-left: 20px;
      font-size: 36px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    font-size: 18px;

    @include xs-to-sm {
      padding: 0 20px;
    }

    p {
      margin-bottom: 1.375rem;
    }

    .top {
      display: flex;
      justify-content: space-between;
      margin-top: 70px;

      @include xs-to-sm {
        flex-direction: column;
        margin-top: 20px;
      }

      .topContent {
        width: 100%;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-top: 45px;

      @include xs-to-sm {
        flex-direction: column;
      }

      .bottomContent {
        width: 50%;

        @include xs-to-sm {
          width: 100%;
        }
      }
    }

    .image-1 {
      width: 50%;
      object-fit: fill;
      margin-right: 100px;
      @include xs-to-sm {
        margin: 0;
        width: 100%;
      }
    }

    .image-2 {
      margin-left: 100px;

      @include xs-to-sm {
        margin: 0;
        width: 100%;
      }
    }
  }
}
