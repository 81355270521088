@import '../../styles/mixins.scss';

.linkDetail {
  max-width: 500px;
  margin-top: 30px;

  @include xs-to-sm {
    display: inline-block;
    margin-top: 10px;
    max-width: 100vw;
    flex: 100%;
  }

  .abbr {
    @include roboto-bold;
  }

  & ul {
    margin-left: -20px;
    margin-top: 5px;

    & li {
      margin-bottom: 10px;
    }

    & li p {
      margin-bottom: 3px;

      @include xs-to-sm {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }

    & li a {
      @include xs-to-sm {
        max-width: 100vw;
        display: inline-block;
      }
    }
  }

  .link {
    width: 100%;

    & a {
      word-break: break-all;

      @include xs-to-sm {
        margin: 0;
      }
    }
    @include xs-to-sm {
      margin: 0;
    }
  }
}
