@import '../../styles/mixins.scss';

.whatSection {
  display: flex;
  justify-content: space-between;
  margin-top: 85px;

  @include xs-to-sm {
    flex-direction: column;
    margin-top: 58px;
  }

  .left {
    margin-left: 54px;

    .caption {
      text-align: center;
      font-size: 12px;
    }

    & img {
      width: 504px;
      height: 504px;
      object-fit: fill;
      height: auto;

      @include xs-to-sm {
        width: 100%;
        height: 100%;
        padding: 0 20px;
      }
    }

    & img.after {
      background-color: white;
    }

    & img.custom-handle {
      width: 50px;
      height: auto;
      padding: 0;
      margin-left: -4px;
      margin-top: 120px;
    }

    @include xs-to-sm {
      margin: 0;
    }
  }

  .right {
    margin-left: 72px;
    margin-right: 112px;
    margin-top: 90px;

    .title {
      @include roboto-bold;
      font-size: 42px;

      @include xs-to-sm {
        font-size: 36px;
      }
    }

    .content {
      margin-top: 20px;
      font-size: 18px;
    }

    @include xs-to-sm {
      margin: 0;
      padding: 50px 20px 0 20px;
    }
  }
}
