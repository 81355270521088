@import '../../styles/mixins.scss';

.hero {
  display: flex;

  @include xs-to-sm {
    flex-direction: column-reverse;
    align-items: center;
  }

  .left {
    margin-left: 100px;
    margin-top: 108px;

    @include xs-to-sm {
      margin: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .text {
      @include roboto-bold;
      width: 400px;
      line-height: 49px;
      font-size: 50px;

      @include xs-to-sm {
        width: 100%;
        margin: 0;
        text-align: center;
        padding: 0 20px;
        font-size: 35px;
        line-height: 37px;
      }
    }

    .callToAction {
      width: 191px;
    }
  }

  .right {
    z-index: 1000;
    margin-left: 40px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include xs-to-sm {
        width: 100%;
      }
    }

    @include xs-to-sm {
      margin: 0;

      width: 360px;
      height: auto;
    }
  }
}

.callToAction {
  @include roboto-medium;
  margin-top: 28px;
  font-size: 23px;
  background: linear-gradient(180deg, #343434, #161616);

  @include xs-to-sm {
    margin: 0 auto;
    margin-top: 17px;
    width: 140px;
    font-size: 18px;
    line-height: 22px;
  }
}
