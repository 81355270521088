/**
 * Typography-related mixins:
 *  - Font styles
 *  - Heading text styles
 */

/// FONTS

// Roboto
@mixin roboto-regular {
  font-family: 'Roboto-Regular', Helvetica, Arial, sans-serif !important;
}

@mixin roboto-medium {
  font-family: 'Roboto-Medium', Helvetica, Arial, sans-serif !important;
}

@mixin roboto-bold {
  font-family: 'Roboto-Bold', Helvetica, Arial, sans-serif !important;
}

@mixin roboto-medium-italic {
  font-family: 'Roboto-Medium-Italic', Helvetica, Arial, sans-serif !important;
}
