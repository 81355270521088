/**
 * Global styles.
 *
 * IMPORTANT: These styles are imported at the root of the application. You
 * should never import them explicitely anywhere else in stylesheets created
 * for separate components. In case you need some mixins, you should place
 * them into "mixins.scss" and include that into component stylesheets!
 */

@import 'mixins';

html,
body {
  @include roboto-regular;
  max-width: $screen-lg;
  margin: 0 auto;
  color: #121212;
}

.text-blue {
  color: $blue;
}

.text-green {
  color: $green;
}

img-comparison-slider {
  --divider-width: 1px;
  --divider-color: $dark;
  --handle-opacity: 1;
  --handle-opacity-active: 1;
}
