@import './styles/mixins.scss';

.App {
  position: relative;
  background-image: url('./assets//navbar-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 612px;

  @include xs-to-sm {
    height: 150vh;
  }
}

.overlay-big {
  position: absolute;
  right: 0;
  background: linear-gradient(90deg, $dark 0%, $dark 50%);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  width: 0.88 * $screen-md;
  height: 407px;

  @include xs-to-sm {
    width: 60%;
    height: 125px;
  }
}

.overlay-small {
  position: absolute;
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $white 100%);
  opacity: 0.16;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  width: 0.65 * $screen-md;
  max-width: $screen-md;
  height: 304px;

  @include xs-to-sm {
    width: 56%;
    height: 115px;
    background: $white;
  }
}
